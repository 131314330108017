@import '../styles/scss/precss/precss';

$interaction-list-class-prefix: $ws10-css-prefix + '-interaction-list';
$interaction-list-class-prefix-item: $interaction-list-class-prefix + '-item';
$transition: $duration-200 $ease-in-sine;
$space-mobile: $space-75;
$space-desktop: $space-100;

.#{$interaction-list-class-prefix} {
    @include ws10-text(false);

    &--flat {
        .#{$interaction-list-class-prefix}__item {
            margin-bottom: $space-0;

            // prevent double borders between two items
            &:not(:last-child) > * {
                border-bottom: 0;
            }
        }

        .#{$interaction-list-class-prefix-item} {
            $border: $border-width-small $border-style-solid $color-monochrome-300;

            border-top: $border;
            border-bottom: $border;
        }
    }

    &--card {
        .#{$interaction-list-class-prefix}__item {
            margin-bottom: $space-75;

            @include mq(small) {
                margin-bottom: $space-100;
            }
        }

        .#{$interaction-list-class-prefix-item} {
            padding: $space-mobile;
            background-color: $color-monochrome-100;
            border-radius: $border-radius-medium;
            box-shadow: $shadow-medium;

            @include mq(small) {
                padding: $space-desktop;
            }
        }
    }
}

.#{$interaction-list-class-prefix-item} {
    padding: $space-mobile 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-monochrome-600;

    @include mq(small) {
        padding: $space-desktop 0;
    }

    @media (hover: hover) {
        &:focus-visible,
        &:hover {
            outline: unset;
            text-decoration: none;

            // placed here for higher specificity than "ws10-text" style
            .#{$interaction-list-class-prefix}__label {
                color: $color-primary-200;
            }
        }

        &:focus-visible {
            @include brix-focus(false);
        }
    }

    &__label {
        margin-right: $space-mobile;
        flex-grow: 1;
        transition: color $transition;

        @include mq(small) {
            margin-right: $space-desktop;
        }
    }

    &__icon {
        flex-shrink: 0;

        &--label {
            margin-right: $space-mobile;

            @include mq(small) {
                margin-right: $space-desktop;
            }
        }
    }
}
