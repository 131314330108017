/** To generate on overview of all design tokens, this file will be parsed by tools/generate-overview.js */
@import 'color';

/**
 * @tokens Borders
 * @presenter Border
 */

$border-style-dashed: dashed;
$border-style-solid: solid;

/**
 * @tokens-end
 */

$border-styles: (
    'dashed': $border-style-dashed,
    'solid': $border-style-solid
);

/**
 * @tokens Borders
 * @presenter Border
 */

$border-none: 0;
$border-width-small: 1px;
$border-width-medium: 2px;
$border-width-large: 3px;
$border-width-x-large: 5px;
$border-width-xx-large: 10px;

/**
 * @tokens-end
 */

$border-widths: (
    'none': $border-none,
    'small': $border-width-small,
    'medium': $border-width-medium,
    'large': $border-width-large,
    'x-large': $border-width-x-large,
    'xx-large': $border-width-xx-large
);

/**
 * @tokens Borders
 * @presenter BorderRadius
 */

$border-radius-small: 3px;
$border-radius-medium: 6px;
$border-radius-large: 10px;
$border-radius-pill: 100px;

/** 50% becomes elliptical when width==height */
$border-radius-half: 50%;

/**
 * @tokens-end
 */

$border-radii: (
    'small': $border-radius-small,
    'medium': $border-radius-medium,
    'large': $border-radius-large,
    'pill': $border-radius-pill,
    'half': $border-radius-half
);

/* ## Border Helpers */

/* Combine border tokens to valid css border attributes */
$border-dashed-grey: $border-width-small $border-style-dashed $color-monochrome-300;
$border-solid-grey: $border-width-small $border-style-solid $color-monochrome-300;
$border-small: $border-width-small $border-style-solid;
$border-medium: $border-width-medium $border-style-solid;
$border-large: $border-width-large $border-style-solid;
$border-x-large: $border-width-x-large $border-style-solid;
$border-xx-large: $border-width-xx-large $border-style-solid;

// TODO: replace later with $
// brix-focus-style when there is a global guide
$focus-outline: inset 0 0 0 $border-width-small $color-monochrome-100, inset 0 0 0 $border-width-medium $color-secondary-600, inset 0 0 0 ($border-width-medium + $border-width-small) $color-monochrome-100;
