@import '../styles/scss/precss/precss';

$icon-pattern-class: 'ws10-icon';
$system-icon-class: $icon-pattern-class + '--system';
$mid-render-icon-class: $icon-pattern-class + '--mid';

.#{$icon-pattern-class} {
    @include ws10-text(false);

    &__img,
    &__svg {
        width: inherit;
        height: inherit;
    }

    &--small {

        &.#{$mid-render-icon-class} {
            width: $size-icon-225;
            height: $size-icon-225;
        }

        &.#{$system-icon-class} {
            width: $size-icon-125;
            height: $size-icon-125;
        }
    }

    &--medium {

        &.#{$mid-render-icon-class} {
            width: $size-icon-300;
            height: $size-icon-300;
        }

        &.#{$system-icon-class} {
            width: $size-icon-150;
            height: $size-icon-150;
        }
    }

    &--large {

        &.#{$mid-render-icon-class} {
            width: $size-icon-400;
            height: $size-icon-400;
        }

        &.#{$system-icon-class} {
            width: $size-icon-200;
            height: $size-icon-200;
        }
    }

    /* Fallback, when system and mid are both maintained */
    &.#{$system-icon-class} {

        .#{$icon-pattern-class}__img {
            display: none;
        }
    }
}
