@import '../styles/scss/precss/precss';
@import '../system-icon/system-icon';

$class-prefix: $ws10-css-prefix + '-button-link';
$transition: $duration-200 $ease-in-sine;

// Color Variants
// Structure: name color color-hover color-disabled
$ws10-button-link-color-variants: (
    'color-primary-200' $color-primary-200 $color-primary-200 $color-monochrome-300,
    'color-primary-200-inverse' $color-primary-200-inverse $color-primary-200-inverse $color-monochrome-300-inverse,
    'color-monochrome-600' $color-monochrome-600 $color-primary-200 $color-monochrome-300,
    'color-monochrome-600-inverse' $color-monochrome-600-inverse $color-monochrome-600-inverse $color-monochrome-300-inverse,
);

.#{$class-prefix} {
    @include ws10-text(false);

    appearance: none;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: $border-width-small $border-style-solid transparent;
    padding-bottom: $space-15;
    color: var(--color);
    text-decoration: none;
    transition: color $transition, border $transition;
    background-color: transparent;

    &:not(&--disabled):hover,
    &:not(&--disabled):active,
    &:not(&--disabled):focus-visible {
        cursor: pointer;
        outline: unset;
        border-bottom: $border-width-small $border-style-solid currentcolor;
        color: var(--color-hover);
        text-decoration: none;

        // placed here for higher specificity
        & .#{$class-prefix}__icon {
            color: var(--color-hover);
        }
    }

    &:focus {
        // remove underline which comes from simplicity
        text-decoration: none;
    }

    &:focus-visible {
        @include brix-focus(false);
    }

    // Set up css variables for each variant
    @each $name, $color, $color-hover, $color-disabled in $ws10-button-link-color-variants {
        &--#{$name} {
            --color: #{$color};
            --color-hover: #{$color-hover};
            --color-disabled: #{$color-disabled};
        }
    }

    &__icon {
        flex-shrink: 0;
        transition: fill $transition, stroke $transition;

        &--left {
            margin-right: $space-25;
        }

        &--right {
            margin-left: $space-25;
        }
    }

    // stylelint-disable-next-line no-duplicate-selectors
    & {
        .#{$class-prefix}__text {
            color: inherit;
        }
    }

    &--disabled,
    &--disabled:focus {
        cursor: not-allowed;
        color: var(--color-disabled);

        // placed here for higher specificity
        & .#{$class-prefix}__icon {
            color: var(--color-disabled);
        }
    }
}
