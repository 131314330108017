@use 'sass:map';
@import '../styles/scss/precss/precss';

$button-class-prefix: 'ws10-button';
$button-with-icon-class: $button-class-prefix + '--icon';

// stylelint-disable-next-line value-list-max-empty-lines
$button-color-variations: (
    primary: (
        // text color
        color: $color-monochrome-100,
        // default color
        default: $color-primary-100,
        // hover color
        hover: hover($color-primary-100),
        // focus color
        focus: hover($color-primary-100),
        // active/press color
        active: press($color-primary-100),

    ),
    primary-inverse: (
        // text color
        color: $color-monochrome-600-inverse,
        // default color
        default: $color-primary-100-inverse,
        // hover color
        hover: hover($color-primary-100-inverse),
        // focus color
        focus: hover($color-primary-100-inverse),
        // active/press color
        active: press($color-primary-100-inverse),
    ),
    secondary: (
        // text color
        color: $color-monochrome-100,
        // default color
        default: $color-monochrome-600,
        // hover color
        hover: hover($color-monochrome-600),
        // focus color
        focus: hover($color-monochrome-600),
        // active/press color
        active: press($color-monochrome-600),
    ),
    secondary-inverse: (
        // text color
        color: $color-monochrome-100-inverse,
        // default color
        default: $color-monochrome-600-inverse,
        // hover color
        hover: hover($color-monochrome-600-inverse),
        // focus color
        focus: hover($color-monochrome-600-inverse),
        // active/press color
        active: press($color-monochrome-600-inverse),
    ),
    tertiary: (
        // default color will be transparent
        // text color
        color: $color-monochrome-600,
        // hover color
        hover: hover($color-monochrome-600),
        // focus color
        focus: hover($color-monochrome-600),
        // active/press color
        active: press($color-monochrome-600),
    ),
    tertiary-inverse: (
        // default color will be transparent
        // text color
        color: $color-monochrome-600-inverse,
        // hover color
        hover: hover($color-monochrome-600-inverse),
        // focus color
        focus: hover($color-monochrome-600-inverse),
        // active/press color
        active: press($color-monochrome-600-inverse),
    ),
    disabled: (
        // text color
        color: $color-monochrome-400,
        // default color
        default:$color-monochrome-300,
        // hover color
        hover: $color-monochrome-300,
        // focus color
        focus: $color-monochrome-300,
        // active/press color
        active: $color-monochrome-300,
    ),
    disabled-inverse: (
        // text color
        color: $color-monochrome-400-inverse,
        // default color
        default:$color-monochrome-300-inverse,
        // hover color
        hover: $color-monochrome-300-inverse,
        // focus color
        focus: $color-monochrome-300-inverse,
        // active/press color
        active: $color-monochrome-300-inverse,
    )
);
$button-size-variations: (
    small: $size-button-small,
    standard: $size-button-medium,
);

/** to set the background color and text color
 * @param variation
*/
@mixin button-color-scheme($variation) {
    background-color: map.get($variation, default);

    &,
    &:visited {
        color: map.get($variation, color);
    }

    @media (hover: hover) {
        &:hover {
            background-color: map.get($variation, hover);
        }
    }

    &:focus-visible:not([disabled]) {
        background-color: map.get($variation, focus);
    }

    // stylelint-disable-next-line no-descending-specificity
    &:active {
        background-color: map.get($variation, active);
    }
}

/**
 * @param color
*/
@mixin svg-color($color) {
    color: $color;
}

/** to set the properties of the tertiary and inverse
 * @param border color
 * @param text color
 * @param svg color while having event
 * @param svg color in default
*/
@mixin tertiary-behavior($border-color, $color, $svg-color-event, $svg-color) {
    border: $border-width-medium $border-style-solid $border-color;
    box-shadow: unset;

    $hover-color: hover($border-color);
    $active-color: press($border-color);

    &:active,
    &:focus-visible:not([disabled]) {
        border-color: $hover-color;
        // stylelint-disable-next-line declaration-no-important
        color: $color !important;
    }

    &.#{$button-with-icon-class}:focus,
    &.#{$button-with-icon-class}:active {
        .#{$ws10-css-prefix}-system-icon {
            transition: all $duration-200 $ease-in-sine;

            @include svg-color($svg-color-event);
        }
    }

    @media (hover: hover) {
        &:hover {
            border-color: $hover-color;
            // stylelint-disable-next-line declaration-no-important
            color: $color !important;
        }

        &.#{$button-with-icon-class}:hover {
            .#{$ws10-css-prefix}-system-icon {
                transition: all $duration-200 $ease-in-sine;

                @include svg-color($svg-color-event);
            }
        }
    }

    // stylelint-disable-next-line no-descending-specificity
    &:active {
        border-color: $active-color;
    }

    &.#{$button-with-icon-class} {
        .#{$ws10-css-prefix}-system-icon {
            @include svg-color($svg-color);
        }
    }
}

.#{$button-class-prefix} {
    // reset
    border-radius: $border-radius-medium;
    background: $transparent;
    appearance: none;
    font-size: $font-size-150;

    // styling
    padding: $space-75 $space-125;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: $font-weight-normal;
    height: map.get($button-size-variations, standard);

    &:visited,
    &:focus {
        cursor: pointer;
        text-decoration: none;
        outline: unset;
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
        outline: unset;
    }

    &:focus-visible:not([disabled]) {
        @include brix-focus(false);
    }

    &:active {
        transition: all $duration-200 $ease-in-sine;
        // stylelint-disable-next-line declaration-no-important
        box-shadow: none !important;
    }

    @media (hover: hover) {
        &:hover {
            transition: all $duration-200 $ease-in-sine;
        }
    }

    &--tertiary {
        @include tertiary-behavior($color-monochrome-600, $color-monochrome-100, $color-monochrome-100, $color-monochrome-600);
    }

    &--tertiary-inverse {
        @include tertiary-behavior($color-monochrome-600-inverse, $color-monochrome-100-inverse, $color-monochrome-100-inverse, $color-monochrome-600-inverse);
    }

    &--disabled,
    &--disabled-inverse {
        border: $zero;

        &:hover {
            cursor: not-allowed;
        }
    }

    &--small {
        font-size: $font-size-100;
        padding: $space-25 $space-100;
        font-weight: $font-weight-bold;
    }

    // Variations
    @each $name, $size in $button-size-variations {
        &--#{$name} {
            height: $size;
        }
    }

    @each $name, $variation in $button-color-variations {
        &--#{$name} {
            @include button-color-scheme($variation);
        }
    }

    &--fullwidth {
        width: 100%;
        justify-content: center;
    }

    &--hybrid {
        @include mq(mobile-only) {
            width: 100%;
            justify-content: center;
        }
    }

    @include mq(small) {
        &--standard {
            font-size: $font-size-200;
            height: $size-button-large;
        }
    }

    &[aria-checked='true'] {
        $button-checked-color: press($color-monochrome-600);
        $button-hover-color: hover($color-monochrome-600);

        background: $button-checked-color;
        border-color: $button-checked-color;
        color: $color-monochrome-100;

        @media (hover: hover) {
            &:hover {
                background: $button-hover-color;
                border-color: $button-hover-color;
                color: $color-monochrome-100;
            }
        }

        .#{$ws10-css-prefix}-system-icon {
            display: block;
            // stylelint-disable-next-line declaration-no-important
            color: $color-monochrome-100 !important;
        }
    }
}

.#{$button-with-icon-class} {
    .#{$ws10-css-prefix}-system-icon {
        width: $size-icon-150;
        height: $size-icon-150;

        @include svg-color($color-monochrome-100);
    }

    @include mq(small) {
        .#{$ws10-css-prefix}-system-icon {
            width: $size-icon-200;
            height: $size-icon-200;
        }
    }

    &-position-left {
        .#{$ws10-css-prefix}-system-icon {
            margin-right: $space-50;
        }
    }

    &-position-right {
        .#{$ws10-css-prefix}-system-icon {
            order: 2;
            margin-left: $space-50;
        }
    }

    &.#{$button-class-prefix}--small {
        .#{$ws10-css-prefix}-system-icon {
            // icons are not allowed in small buttons
            display: none;
        }
    }

    &.#{$button-class-prefix}--secondary-inverse {
        .#{$ws10-css-prefix}-system-icon {
            @include svg-color($color-monochrome-100-inverse);
        }
    }

    &.#{$button-class-prefix}--disabled {
        .#{$ws10-css-prefix}-system-icon {
            @include svg-color($color-monochrome-400);
        }
    }

    &.#{$button-class-prefix}--disabled-inverse {
        .#{$ws10-css-prefix}-system-icon {
            @include svg-color($color-monochrome-400-inverse);
        }
    }
}
