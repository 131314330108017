@import '../styles/scss/precss/precss';

$headline-class: $ws10-css-prefix + '-headline';

.#{$headline-class} {
    /*
        base styling h1-h5 are located in:
        /packages/styles/src/css/accessories/_headlines.scss
        don't move it because of CMS reasons!
    */

    &--left {
        text-align: left;
    }

    &--center {
        text-align: center;
    }

    &--mobile-left {
        @include mq(mobile-only) {
            text-align: left;
        }
    }

    &--mobile-center {
        @include mq(mobile-only) {
            text-align: center;
        }
    }

    &--mobile-hidden {
        @include mq(mobile-only) {
            display: none;
        }
    }

    &--with-counter,
    .#{$ws10-css-prefix}-counter--headline &--count-me {
        display: flex;
        // stylelint-disable-next-line declaration-no-important
        line-height: 30px !important; /* Needs to be same height as counter icon to center it vertically */

        &::before {
            box-sizing: border-box;
            font-size: $font-size-200;
            font-weight: $font-weight-normal;
            border: $border-width-small $border-style-solid $color-monochrome-600;
            border-radius: $border-radius-half;
            width: 30px;
            display: inline-flex;
            justify-content: center;
            height: 30px;
            vertical-align: middle;
            align-items: center;
            margin-right: $space-75;
            flex-shrink: 0;
        }
    }

    &--with-counter {
        &::before {
            content: attr(data-number);
        }
    }

    .#{$ws10-css-prefix}-counter--headline &--count-me,
    .#{$ws10-css-prefix}-counter--headline-simple &--count-me {
        &::before {
            counter-increment: headline;
            content: counter(headline);
        }
    }

    .#{$ws10-css-prefix}-counter--headline-simple &--count-me {
        &::before {
            content: counter(headline) '. ';
        }
    }
}

.#{$ws10-css-prefix}-counter--headline,
.#{$ws10-css-prefix}-counter--headline-simple {
    counter-reset: headline;
}
