@use 'sass:math';

/**
 * Tokens for Size, button size and spacing are all based on 16px and multiple of 16px
 * So i implement the full range of these spacial scale here and reuse it in the tokens accordingly

 * The underscore indicates that is not a token itself by a private helper ;)
 */

/**
 * @tokens Spacings
 */

$spatial-scale-base: 16px;
$spatial-scale-0: math.round($spatial-scale-base * 0);           /* 0px */

/** For reasons that is 15, but should be 125 */
$spatial-scale-15: math.round($spatial-scale-base * .125);       /* 2px */
$spatial-scale-25: math.round($spatial-scale-base * .25);        /* 4px */
$spatial-scale-50: math.round($spatial-scale-base * .5);         /* 8px */
$spatial-scale-75: math.round($spatial-scale-base * .75);        /* 12px */
$spatial-scale-100: math.round($spatial-scale-base * 1);         /* 16px */
$spatial-scale-125: math.round($spatial-scale-base * 1.25);      /* 20px */
$spatial-scale-150: math.round($spatial-scale-base * 1.5);       /* 24px */
$spatial-scale-200: math.round($spatial-scale-base * 2);         /* 32px */
$spatial-scale-225: math.round($spatial-scale-base * 2.25);      /* 36px */
$spatial-scale-250: math.round($spatial-scale-base * 2.5);       /* 40px */
$spatial-scale-275: math.round($spatial-scale-base * 2.75);      /* 44px */
$spatial-scale-300: math.round($spatial-scale-base * 3);         /* 48px */
$spatial-scale-350: math.round($spatial-scale-base * 3.5);       /* 56px */
$spatial-scale-400: math.round($spatial-scale-base * 4);         /* 64px */
$spatial-scale-450: math.round($spatial-scale-base * 4.5);       /* 72px */
$spatial-scale-500: math.round($spatial-scale-base * 5);         /* 80px */
$spatial-scale-525: math.round($spatial-scale-base * 5.25);      /* 84px */
$spatial-scale-600: math.round($spatial-scale-base * 6);         /* 96px */
$spatial-scale-750: math.round($spatial-scale-base * 7.5);       /* 120px */
$spatial-scale-1200: math.round($spatial-scale-base * 12);       /* 192px */

/**
 * @tokens-end
 */
