/** @define tabs */

@import '../styles/scss/precss/precss';

$tabs-class-prefix: $ws10-css-prefix + '-tabs';

.#{$tabs-class-prefix} {
    background-color: $color-monochrome-100;

    &--inverse {
        color: $color-monochrome-100;
        background-color: $color-monochrome-600;
    }

    &__control {
        max-width: 100%;
        overflow-x: auto;
        scroll-behavior: smooth;

        // hide scrollbars
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__track {
        display: flex;

        &--auto-width {
            width: max-content;
            margin: 0 auto;
        }
    }

    &__track--auto-width > &__tab {
        flex: 0 0 auto;
    }

    &__tab {
        position: relative;
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: $space-25;

        &--active > button {
            &::after {
                background-color: $color-primary-100;
            }

            & > .#{$tabs-class-prefix}__label {
                font-weight: $font-weight-bold;
            }
        }
    }

    &__button {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: $space-50 $space-100;
        background-color: transparent;
        cursor: pointer;

        // View with icon and 2 line text optimalization
        &--icon {
            padding-bottom: 0;

            .#{$tabs-class-prefix}__icon {
                margin-bottom: $space-15;
            }

            .#{$tabs-class-prefix}__label {
                display: flex;
                align-items: center;
                min-height: 40px;
                line-height: 1.1;
            }
        }

        &:disabled {
            opacity: $opacity-20;
            cursor: default;
        }

        &:focus-visible {
            @include brix-focus;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            height: $space-25;
            background-color: transparent;
            border-radius: $border-radius-small $border-radius-small 0 0;
            pointer-events: none;
        }
    }

    &__button:not(:disabled):hover {
        &::after {
            background-color: $color-primary-100;
        }
    }

    &--inverse &__icon,
    &--inverse &__label,
    &--inverse &__contents h2,
    &--inverse &__contents h3,
    &--inverse &__contents h4,
    &--inverse &__contents h5,
    &--inverse &__contents h6,
    &--inverse &__contents p {
        color: $color-monochrome-100;
    }

    &__icon {
        $icon-color: currentcolor;

        flex-basis: 100%;
        width: $size-icon-200;
        height: $size-icon-200;
        margin-bottom: $space-50;
        color: $icon-color;

        @include mq(small) {
            width: $size-icon-225;
            height: $size-icon-225;
        }
    }

    &__label {
        @include ws10-text(false);

        align-self: flex-end;
    }

    &__track--auto-width &__label {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after {
            content: attr(data-text) / '';
            height: 0;
            visibility: hidden;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            font-weight: $font-weight-bold;
        }
    }

    &__icon + &__label {
        align-self: auto;
    }

    &__contents {
        margin-top: 0;
        padding: $space-100;

        @include mq(small) {
            padding: $space-150 0;
        }
    }

    &--grey &__contents {
        @include ws10-text(false);

        background-color: $color-monochrome-200;
    }

    &__content {
        display: none;

        &--active {
            display: block;
        }
    }
}
