/* ==========================================================================
   #DIVIDER-LINE
   ========================================================================== */
.#{$ws10-css-prefix}-divider-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color-monochrome-300;
    margin: $space-75 0;
    padding: 0;
    border: 0 solid #fff;
}
