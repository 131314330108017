@import '../styles/scss/precss/precss';

$container-prefix: 'ws10-belt-container';

.#{$container-prefix} {
    &--bg-monochrome-100 {
        background: $color-monochrome-100;
    }

    &--bg-monochrome-200 {
        background: $color-monochrome-200;
    }

    &--bg-primary-300 {
        background: $color-primary-300;
    }

    &--bg-monochrome-500 {
        background: $color-monochrome-500;
    }

    &--bg-gradient-100 {
        background: $color-gradient-100;
    }

    &--padding-none {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--padding-medium {
        padding-top: $space-150;
        padding-bottom: $space-150;

        @include mq(small) {
            padding-top: $space-200;
            padding-bottom: $space-200;
        }
    }

    &--padding-large {
        padding-top: $space-200;
        padding-bottom: $space-200;

        @include mq(small) {
            padding-top: $space-300;
            padding-bottom: $space-300;
        }
    }

    &--padding-x-large {
        padding-top: $space-300;
        padding-bottom: $space-300;

        @include mq(small) {
            padding-top: $space-400;
            padding-bottom: $space-400;
        }
    }

    &--border-bottom {
        border-bottom: $border-solid-grey;
    }

    &__belt {
        margin-left: $space-100;
        margin-right: $space-100;
        max-width: $breakpoint-x-large - 2 * $space-100;

        @include mq(x-large) {
            margin-left: auto;
            margin-right: auto;
        }
    }
}
