/**
 * Primary
 * @tokens Colors
 * @presenter Color
 */

$color-primary-100: #e60000;
$color-primary-200: #bd0000;
$color-primary-300: #820000;

/**
 * @tokens-end
 */

$colors-primary: (
    '100': $color-primary-100,
    '200': $color-primary-200,
    '300': $color-primary-300
);

/**
 * Secondary
 * @tokens Colors
 * @presenter Color
 */

$color-secondary-100: #00697c;
$color-secondary-200: #5e2750;
$color-secondary-300: #eb7800;
$color-secondary-400: #fecb00;
$color-secondary-500: #008a00;
$color-secondary-600: #005ea5;

/**
 * @tokens-end
 */

$colors-secondary: (
    '100': $color-secondary-100,
    '200': $color-secondary-200,
    '300': $color-secondary-300,
    '400': $color-secondary-400,
    '500': $color-secondary-500,
    '600': $color-secondary-600
);

/**
 * Monochrome
 * @tokens Colors
 * @presenter Color
 */

$color-monochrome-100: #fff;
$color-monochrome-200: #f2f2f2;
$color-monochrome-300: #bebebe;
$color-monochrome-400: #7e7e7e;
$color-monochrome-500: #262626;
$color-monochrome-600: #0d0d0d;

/**
 * @tokens-end
 */

$colors-monochrome: (
    '100': $color-monochrome-100,
    '200': $color-monochrome-200,
    '300': $color-monochrome-300,
    '400': $color-monochrome-400,
    '500': $color-monochrome-500,
    '600': $color-monochrome-600
);

/* ## Gradient */
$color-gradient-100: linear-gradient(45deg, #820000 0%, #e60000 100%);
$color-gradient-200: linear-gradient(45deg, #f2f2f2 0%, #fafafa 100%);
$color-gradient-300: linear-gradient(45deg, #222 0%, #25282b 100%);
$colors-gradient: (
    '100': $color-gradient-100,
    '200': $color-gradient-200,
    '300': $color-gradient-300
);

/**
 * Primary-Inverse
 * @tokens Colors
 * @presenter Color
 */

$color-primary-100-inverse: #ea1a1a;
$color-primary-200-inverse: #f06666;
$color-primary-300-inverse: #8a0f0f;

/**
 * @tokens-end
 */

$colors-primary-inverse: (
    '100': $color-primary-100-inverse,
    '200': $color-primary-200-inverse,
    '300': $color-primary-300-inverse
);

/**
 * Secondary-Inverse
 * @tokens Colors
 * @presenter Color
 */

$color-secondary-100-inverse: #0096ad;
$color-secondary-200-inverse: #b04a98;
$color-secondary-300-inverse: #eb9700;
$color-secondary-400-inverse: #fecb00;
$color-secondary-500-inverse: #b7bf10;
$color-secondary-600-inverse: #5f9bc8;

/**
 * @tokens-end
 */

$colors-secondary-inverse: (
    '100': $color-secondary-100-inverse,
    '200': $color-secondary-200-inverse,
    '300': $color-secondary-300-inverse,
    '400': $color-secondary-400-inverse,
    '500': $color-secondary-500-inverse,
    '600': $color-secondary-600-inverse
);

/**
 * Monochrome-Inverse
 * @tokens Colors
 * @presenter Color
 */

$color-monochrome-100-inverse: #0d0d0d;
$color-monochrome-200-inverse: #262626;
$color-monochrome-300-inverse: #7e7e7e;
$color-monochrome-400-inverse: #bebebe;
$color-monochrome-500-inverse: #f2f2f2;
$color-monochrome-600-inverse: #fff;

/**
 * @tokens-end
 */

$colors-monochrome-inverse: (
    '100': $color-monochrome-100-inverse,
    '200': $color-monochrome-200-inverse,
    '300': $color-monochrome-300-inverse,
    '400': $color-monochrome-400-inverse,
    '500': $color-monochrome-500-inverse,
    '600': $color-monochrome-600-inverse
);

/**
 * Gradient
 * @tokens Colors
 * @presenter Color
 */

$color-gradient-100-inverse: linear-gradient(45deg, #820000 0%, #e60000 100%);
$color-gradient-200-inverse: linear-gradient(45deg, #222 0%, #25282b 100%);
$color-gradient-300-inverse: linear-gradient(45deg, #f2f2f2 0%, #fafafa 100%);

/**
 * @tokens-end
 */

$colors-gradient-inverse: (
    '100': $color-gradient-100-inverse,
    '200': $color-gradient-200-inverse,
    '300': $color-gradient-300-inverse
);

/**
 * Colors which can be used as a background color (background of a page)
 *
 * Spec: https://www.figma.com/file/Iyc1KFCMIR0KC1RKv3U31F/GridContainer?type=design&node-id=0-1&t=k6Oi0LJO4obatL5Y-0
 */
$colors-as-page-background: (
    'color-monochrome-100': $color-monochrome-100,
    'color-monochrome-200': $color-monochrome-200
);

/**
 * Interaction colors for states like hover and press are generated by mixing a color with white or black
 *
 * UX provided two lists of which  interaction colors need to be darkened or lightened
 *
 * The light list is shorter. So I implemented only that and assume that every color that
 * is not in that lighter list should be darker
 */

$colors-to-be-lighter: $color-monochrome-500, $color-monochrome-600, $color-monochrome-100-inverse, $color-monochrome-200-inverse;

@function contains($list, $var) {
    @return (false == index($list, $var));
}

@function mix-interaction-color($color, $percent) {
    @if index($colors-to-be-lighter, $color) {
        @return mix(#fff, $color, $percent);
    }

    @return mix(#000, $color, $percent);
}

@function hover($color) {
    @return mix-interaction-color($color, 15%);
}

@function press($color) {
    @return mix-interaction-color($color, 30%);
}
