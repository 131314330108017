// import only precss from styles package! This contains all your variables you need!
@import '../styles/scss/precss/precss';

// See Design Tokens page in Brix Explorer for whats available (colors, fonts etc) or check in pre css

$overlay-class-prefix: 'ws10-overlay';

.#{$overlay-class-prefix} {
    @include ws10-text(false);

    display: none;
    z-index: 1001;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    &--align-center {
        .#{$overlay-class-prefix}__content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__backdrop {
        z-index: 1000;
        transition: opacity $duration-500 $ease-in-sine;

        @include mq(small) {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';

            /* @TODO This design token is deprecated. Please update in spec and here on next release. https://www.vodafone.de/brix/docs/ux/design-tokens */
            background-color: $color-monochrome-600;
        }

        &.#{$ws10-css-prefix}-fade.#{$ws10-css-prefix}-in {
            opacity: $opacity-60;
        }
    }

    &__container {
        position: relative;
        background-color: $color-monochrome-100;
        overflow: hidden;
        z-index: 2;
        height: 100%;
        transition: transform $duration-500 $ease-in-sine;
        transform: translateX(0) translateY(0);

        @include mq(large) {
            border-radius: $border-radius-medium;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 900px;
            height: auto;
        }

        @at-root {

            .#{$ws10-css-prefix}-fade & {
                transform: translateX(0) translateY(50%);

                @include mq(large) {
                    transform: translateX(-50%) translateY(-25%);
                }
            }

            .#{$ws10-css-prefix}-fade.#{$overlay-class-prefix}--slide & {
                transform: translateX(100%) translateY(0);

                @include mq(small) {
                    position: absolute;
                    right: -419px;
                    width: 419px;
                    transition: right $duration-500 $ease-in-sine;
                    transform: none;
                }

                @include mq(large) {
                    top: 0;
                    left: auto;
                    height: 100%;
                    transform: none;
                }
            }

            .#{$ws10-css-prefix}-fade.#{$ws10-css-prefix}-in & {
                transform: translateX(0) translateY(0);

                @include mq(large) {
                    transform: translateX(-50%) translateY(-50%);
                }
            }

            .#{$ws10-css-prefix}-fade.#{$overlay-class-prefix}--slide.#{$ws10-css-prefix}-in & {
                &__container {
                    border-radius: 0;
                }

                @include mq(small) {
                    right: 0;
                }

                @include mq(large) {
                    transform: none;
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: $space-100;
        right: $space-100;

        // z-index 21 is used because the image-header uses z-index 20 on the belt-container within it
        // and many overlays contain an image-header in the first position
        z-index: 21;

        @include mq(small) {
            right: $space-200;
        }
    }

    &__content {
        --overlay-padding-top: 0;
        --overlay-padding-bottom: 0;
        --overlay-padding-left: 0;
        --overlay-padding-right: 0;

        margin: 0;
        padding: var(--overlay-padding);
        height: 100%;
        overflow-y: auto;
        overscroll-behavior: contain;
        scrollbar-gutter: stable;
        -webkit-overflow-scrolling: touch;

        @include mq(large) {
            height: auto;
            min-height: 40px + $space-400;
            max-height: 90vh;
        }

        @at-root {
            .#{$ws10-css-prefix}-fade.#{$overlay-class-prefix}--slide & {
                @include mq(large) {
                    height: 100%;
                    max-height: 100vh;
                }
            }
        }

        @at-root {
            .#{$overlay-class-prefix}--spacing & {
                /* padding over the button + button height + padding under the button */
                $padding-top: $size-button-medium + $space-200;

                --overlay-padding-top: #{$padding-top};
                --overlay-padding-bottom: #{$space-150};
                --overlay-padding-left: #{$space-150};
                --overlay-padding-right: #{$space-150};

                margin: 0;
                padding: var(--overlay-padding-top) var(--overlay-padding-right) var(--overlay-padding-bottom) var(--overlay-padding-left);
                height: 100%;

                @include mq(small) {
                    --overlay-padding-bottom: #{$space-200};
                    --overlay-padding-left: #{$space-200};
                    --overlay-padding-right: #{$space-200};
                }

                @include mq(large) {
                    /* padding-top + button height + padding-bottom */
                    min-height: $size-button-medium + $space-400;
                    max-height: 90vh;
                }

                .ws10-image-header:first-child {
                    width: auto;
                    min-width: 100%;
                    margin: (-$padding-top) (-$space-150) 0;

                    @include mq(small) {
                        margin-left: -$space-200;
                        margin-right: -$space-200;
                    }
                }
            }
        }

        > *:last-child {
            margin-bottom: $space-0;
        }
    }

    &.#{$ws10-css-prefix}-fade.#{$overlay-class-prefix}--slide.#{$ws10-css-prefix}-in & {
        &__container {
            border-radius: 0;
        }
    }
}
