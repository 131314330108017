/* ==========================================================================
   #SPACING

   Spacing is always spacing to very next element below

   Every component and block should be specified with an default spacing
   The default should be overwritable by multiples of gutter

   Disabling linter because this file will always complain for the !important
   ========================================================================== */

/* stylelint-disable declaration-no-important  */

$map-space: (
    '0': $space-0,
    '15': $space-15,
    '25': $space-25,
    '50': $space-50,
    '75': $space-75,
    '100': $space-100,
    '150': $space-150,
    '200': $space-200,
    '300': $space-300,
    '400': $space-400,
    '450': $space-450,
    '500': $space-500
);

@each $class, $space in $map-space {
    .#{$ws10-css-prefix}-spacing-#{$class} {
        margin-bottom: $space !important;
    }
}

@each $viewport, $val in $breakpoints {
    @include mq($viewport) {
        @each $class, $space in $map-space {
            .#{$ws10-css-prefix}-spacing-#{$class}--#{$viewport} {
                margin-bottom: $space !important;
            }
        }
    }
}

// Deprecated classes

.#{$ws10-css-prefix}-spacing-doubled {
    margin-bottom: $space-200 !important;
}

.#{$ws10-css-prefix}-spacing-one-plus-half {
    margin-bottom: $space-150 !important;
}

.#{$ws10-css-prefix}-spacing-one {
    margin-bottom: $space-100 !important;
}

.#{$ws10-css-prefix}-spacing-three-quarters {
    margin-bottom: $space-75 !important;
}

.#{$ws10-css-prefix}-spacing-half {
    margin-bottom: $space-50 !important;
}

.#{$ws10-css-prefix}-spacing-quarter {
    margin-bottom: $space-25 !important;
}

.#{$ws10-css-prefix}-spacing-eighth {
    margin-bottom: $space-15 !important;
}

.#{$ws10-css-prefix}-spacing-none {
    margin-bottom: $space-0 !important;
}

/* stylelint-enable */
