/* Headings
   ==========================================================================
*/
$base-class-name: $ws10-css-prefix + '-headline';

h1,
.#{$base-class-name}--h1 {
    @include ws10-h1;
}
.#{$base-class-name}--h1-inverse {
    @include ws10-h1-inverse;
}

h2,
.#{$base-class-name}--h2 {
    @include ws10-h2;
}
.#{$base-class-name}--h2-inverse {
    @include ws10-h2-inverse;
}
.#{$base-class-name}--h2-bold {
    @include ws10-h2-bold;
}
.#{$base-class-name}--h2-bold-inverse {
    @include ws10-h2-bold-inverse;
}

h3,
.#{$base-class-name}--h3 {
    @include ws10-h3;
}
.#{$base-class-name}--h3-inverse {
    @include ws10-h3-inverse;
}

h4,
.#{$base-class-name}--h4 {
    @include ws10-h4;
}
.#{$base-class-name}--h4-inverse {
    @include ws10-h4-inverse;
}

h5,
.#{$base-class-name}--h5 {
    @include ws10-h5;
}
.#{$base-class-name}--h5-inverse {
    @include ws10-h5-inverse;
}
