@use 'sass:map';
@import '../styles/scss/precss/precss';

// Needs to be in this file, otherwise it's not exported together with the package
$badge-color-variations: (
    green: (
        background-color: $color-secondary-500,
        content-color: $color-monochrome-100,
    ),
    yellow: (
        background-color: $color-secondary-400,
        content-color: $color-monochrome-600,
    ),
    red: (
        background-color: $color-primary-100,
        content-color: $color-monochrome-100,
    ),
    burgundy: (
        background-color: $color-primary-300,
        content-color: $color-monochrome-100,
    ),
    black: (
        background-color: $color-monochrome-500,
        content-color: $color-monochrome-100,
    ),
    green-inverse: (
        background-color: $color-secondary-500-inverse,
        content-color: $color-monochrome-100-inverse,
    ),
    yellow-inverse: (
        background-color: $color-secondary-400-inverse,
        content-color: $color-monochrome-100-inverse,
    ),
    red-inverse: (
        background-color: $color-primary-100-inverse,
        content-color: $color-monochrome-600-inverse,
    ),
    burgundy-inverse: (
        background-color: $color-primary-300-inverse,
        content-color: $color-monochrome-600-inverse,
    ),
    black-inverse: (
        background-color: $color-monochrome-600-inverse,
        content-color: $color-monochrome-100-inverse,
    ),
);
$highlight-badge-prefix: $ws10-css-prefix + '-highlight-badge';

.#{$highlight-badge-prefix} {
    @include ws10-text(false);

    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    border-radius: $border-radius-pill;
    max-width: 100%;
    line-height: $line-height-fix-150;

    &--standard {
        $highlight-badge-min-height: 28px;

        min-height: $highlight-badge-min-height;
        padding: $space-0 $space-100;
        font-size: $font-size-150;

        p {
            margin: $space-25 $space-0;
            line-height: inherit;
        }

        @include mq(mobile-only) {
            padding: $space-0 $space-75;
            font-size: $font-size-100;

            p {
                margin: $space-15 $space-0;
            }
        }
    }

    &--small {
        padding: $space-0 $space-75;
        font-size: $font-size-50;
        font-weight: $font-weight-bold;

        p {
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
        }
    }

    @each $name, $variation  in $badge-color-variations {
        &--#{"" + $name} {
            background-color: map.get($variation, background-color);

            $content-color: map.get($variation, content-color);

            color: $content-color;
        }
    }

    &__text,
    p {
        @include ws10-text(false);

        display: block;
        min-width: 0;
        overflow: hidden;
        color: inherit;
    }

    &__icon {
        display: block;
        flex-shrink: 0;
        width: $size-icon-150;
        height: $size-icon-150;

        &--left {
            margin: $space-25 $space-50 $space-25 $space-0;

            @include mq(mobile-only) {
                margin: $space-15 $space-50 $space-15 $space-0;
            }
        }

        &--trigger {
            display: block;
            flex-shrink: 0;
            margin: $space-25 $space-0 $space-25 $space-50;

            @include mq(mobile-only) {
                margin: $space-15 $space-0 $space-15 $space-50;
            }
        }

        &--trigger:focus-visible {
            border-radius: $border-radius-half;

            @include brix-focus;
        }
    }
}
