/**
 * Opacity
 * @tokens Opacity
 * @presenter Opacity
 */

$opacity-10: .1;
$opacity-20: .2;
$opacity-60: .6;

/**
 * @tokens-end
 */

$opacities: (
    '10': $opacity-10,
    '20': $opacity-20,
    '60': $opacity-60
);
