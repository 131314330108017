/** @define link-tiles */

@import '../styles/scss/precss/precss';

$link-tiles-class-prefix: $ws10-css-prefix + '-link-tiles';

.#{$link-tiles-class-prefix} {
    &__link-label {
        font-size: $font-size-100;
        font-weight: $font-weight-bold;
        line-height: $line-height-100;
        color: $color-monochrome-600;

        @include mq(medium) {
            font-size: $font-size-150;
            text-align: center;
        }
    }

    &__alongside-pattern-container {
        display: none;

        @include mq(medium) {
            display: grid;
            height: 100%;
            background-color: $color-monochrome-300;
        }

        @include mq(large) {
            height: 100%;
        }
    }

    &__list {
        display: flex;
        margin-bottom: $space-100;
        flex-direction: column;
        flex-basis: 0;
        flex-grow: 1;
        margin-top: 0;

        @include mq(medium) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $space-100;
            grid-row-gap: $space-100;
            // stylelint-disable
            grid-template-areas:
                ". .";
            // stylelint-enable

            &-items-per-row-on-large--3,
            &-items-per-row-on-large--4,
            &-items-per-row-on-large--5 {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        @include mq(large) {
            grid-gap: $space-200;

            &-items-per-row-on-large--5 {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }

            &-items-per-row-on-large--4 {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            &-items-per-row-on-large--3 {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    &__item {
        $margin-fix: -1px;

        margin: $zero $zero $space-75 $margin-fix;

        @include mq(medium) {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--disabled {
            cursor: not-allowed;

            .#{$link-tiles-class-prefix} {
                &__link {
                    background-color: $color-monochrome-300;
                    pointer-events: none;

                    &:hover {
                        box-shadow: $shadow-medium;
                    }
                }

                &__icon {
                    opacity: $opacity-20;
                }

                &__highlight-badge {
                    opacity: $opacity-20;
                }

                &__link-label {
                    color: $color-monochrome-400;
                }
            }
        }
    }

    &__link {
        display: flex;
        flex-grow: 1;
        flex-basis: 25%;
        outline: none;
        font-size: $font-size-500;
        font-weight: $font-weight-bold;
        background: $color-monochrome-100;
        width: 100%;
        height: 80px;
        padding: $space-100;
        border-radius: $border-radius-medium;
        box-shadow: $shadow-medium;
        cursor: pointer;
        align-items: center;

        @include mq(medium) {
            height: 148px;
            justify-content: flex-start;
            flex-direction: column;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:hover {
            box-shadow: $shadow-large;
        }

        &:active {
            box-shadow: $shadow-small;
        }

        &:focus-visible {
            @include brix-focus;
        }

        &:focus {
            outline: none;
        }
    }

    &__action-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq(medium) {
            align-items: center;
        }
    }

    &__icon {
        height: $size-icon-300;
        width: $size-icon-300;
        margin-right: $space-75;
        display: flex;
        align-self: center;

        @include mq(medium) {
            flex-direction: column;
            flex-wrap: wrap;
            margin-right: 0;
            margin-bottom: $space-50;
        }
    }

    &__highlight-badge {
        display: flex;
        margin-top: $space-25;

        @include mq(medium) {
            margin-top: $space-50;
        }

        > div:not(.#{$ws10-css-prefix}-highlight-badge) {
            // in the CMS the highlight-badge always has a wrapper div
            // so the following makes sure the spacing will not break
            display: flex;
        }
    }
}
