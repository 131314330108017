/**
 * Shadows
 * @tokens Shadows
 * @presenter Shadow
 */

$shadow-x-small: 0 1px 3px rgba(0, 0, 0, $opacity-60);
$shadow-small: 0 1px 4px rgba(0, 0, 0, $opacity-20);
$shadow-medium: 0 2px 8px rgba(0, 0, 0, $opacity-20);
$shadow-large: 0 4px 10px rgba(0, 0, 0, $opacity-20);

/**
 * @tokens-end
 */

$shadows: (
    'x-small': $shadow-x-small,
    'small': $shadow-small,
    'medium': $shadow-medium,
    'large': $shadow-large
);

@mixin brix-focus($inset: true, $offset: 0, $elementBackgroundColor: $color-monochrome-100) {
    outline: none;

    @if $inset {
        box-shadow: inset 0 0 0 1px $color-monochrome-100, inset 0 0 0 3px #005fcc, inset 0 0 0 4px $color-monochrome-100;
    }
    @else {
        box-shadow: 0 0 0 ($offset - 2px) $elementBackgroundColor, 0 0 0 $offset $color-monochrome-100, 0 0 0 ($offset + 2px) #005fcc, 0 0 0 ($offset + 4px) $color-monochrome-100;
    }
}
