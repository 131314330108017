// import only precss from styles package! This contains all your variables you need!
@import '../styles/scss/precss/precss';

/**
 * ATTENTION
 *
 *
 * This file is too large and too nested.
 * In between the linter is switched off and on.
 *
 * I hereby declare this file a technical debt.
 * Nobody is allowed to change anything else, unless it is a big refactoring to remove the debt.
 *
 * In any case: Contact the frontend CoE before(!) you do anything here
 *
 * bastian.pertz1@vodafone.com

 */

$form-selection-control-class-prefix: $ws10-css-prefix + '-form-selection-control';

.#{$form-selection-control-class-prefix} {
    $main-circle: 20px;
    $inner-circle: $main-circle - $space-15 - $space-15;
    $outer-circle: $main-circle + $space-75 + $space-15;
    $switch-height: 32px;
    $switch-height-50: calc($switch-height / 2);
    $switch-width: 32px;
    $switch-full-width: 56px;
    $switch-width-50: calc($switch-full-width / 2);
    $switch-width-25: calc($switch-full-width / 4);
    $switch-hover-circle: 48px;
    $check-icon-size: $main-circle - 5px;

    @include ws10-text(false);

    display: flex;
    flex-direction: column;

    &__error-message {
        position: relative;
        line-height: $line-height-100;
        text-decoration: none;
        color: $color-primary-200;
        font-size: $font-size-50;
    }

    &__item {
        position: relative;
        display: inline;
        padding-bottom: $space-100;

        &:nth-last-child(2) {
            padding-bottom: $space-15;
        }
    }

    &__label {
        display: inline-block;
        position: relative;

        $padding-top: 2px;
        $padding-left: $main-circle + $space-75;

        padding: $padding-top 0 0 $padding-left;
        margin: 0 $space-100 0 0;
        cursor: pointer;
        z-index: 1;

        &::before {
            height: $main-circle;
            width: $main-circle;
            border: 1px solid $color-monochrome-400;
        }

        @media (hover: hover) {
            &:hover,
            &:active {
                .#{$form-selection-control-class-prefix}__text {
                    &::before {
                        width: $outer-circle;
                        height: $outer-circle;
                        background-color: $color-secondary-100;
                        opacity: $opacity-20;
                    }
                }
            }
        }

        &:active {
            &::before {
                border-color: $color-secondary-100;
            }
        }
    }

    &__label::before,
    &__label::after,
    &__text::before {
        content: '';
        position: absolute;
        left: calc($main-circle / 2);
        top: calc($main-circle / 2);
        transform: translate(-50%, -50%);
        display: block;
        border-radius: 50%;
        transition: all $duration-200 $ease-in-sine;
    }

    // Overriding the circle for checkbox
    &__label::before,
    &__label::after {
        .#{$form-selection-control-class-prefix}__item--checkbox & {
            border-radius: $border-radius-small;
        }
    }

    &__label::after {
        background: $color-monochrome-100;
        height: 0;
        width: 0;
    }

    &__text {
        &::before {
            width: 0;
            height: 0;
            z-index: 1;
            opacity: $opacity-10;
        }

        p {
            @include ws10-text-small;

            margin: -$space-25 0 0 0;
            color: inherit;
        }

        @include mq(medium) {
            p {
                @include ws10-text(false);
            }
        }
    }

    // Overriding for switch

    /* stylelint-disable */
    &__label {
        .#{$form-selection-control-class-prefix}__item--switch & {
            display: block;
            padding: 4px ($switch-full-width + $space-75) 0 0;
            margin-right: $zero;

            @include mq(medium) {
                padding: 4px 0 0 ($switch-full-width + $space-75);
            }

            .#{$form-selection-control-class-prefix}__text {
                @include ws10-text-small();

                @include mq(medium) {
                    @include ws10-text(false);
                }

                // Reset Link styling for seo reasons
                h1,
                h2,
                h3,
                h4,
                h4,
                h6,
                p {
                    margin-bottom: 0;

                    a {
                        pointer-events: none;
                        &:hover {
                            text-decoration: none;
                        }
                        &:focus {
                            text-decoration: none;
                        }
                    }
                }

                &::before {
                    top: $switch-height-50;  /* 12px */
                    right: $switch-width-25 * 3;  /* 36px */
                    left: auto;
                    transform: translate(0, -50%);

                    @include mq(medium) {
                        right: auto;
                        left: $switch-width-25;  /* 12px */
                    }
                }
            }
            @media (hover: hover) {
                &:hover,
                &:active {
                    .#{$form-selection-control-class-prefix}__text {
                        &::before {
                            width: $switch-hover-circle;
                            height: $switch-hover-circle;
                            right: ($switch-width-25 * 3) - calc($switch-hover-circle / 2) - $border-width-small;  /* 19px */
                            left: auto;

                            @include mq(medium) {
                                right: auto;
                                left: $switch-width-25 - calc($switch-hover-circle / 2) + $border-width-small;  /* -5px */
                            }
                        }
                    }
                }
            }
        }
    }

    &__label::before {
        .#{$form-selection-control-class-prefix}__item--switch & {
            top: $switch-height-50;
            right: 0;
            left: auto;
            transform: translate(0, -50%);
            width: $switch-full-width;
            height: $switch-height;
            border: 0;
            border-radius: $switch-width;
            background: $color-monochrome-400;

            @include mq(medium) {
                left: 0;
                right: auto;
            }
        }
    }

    &__label::after {
        width: $main-circle;
        height: $main-circle;

        .#{$form-selection-control-class-prefix}__item--switch & {
            top: $switch-height-50;
            right: $switch-width-50 - ($border-width-small * 3);  /* 25px */
            left: auto;
            transform: translate(0, -50%);
            width: $switch-width - ($border-width-small * 2);
            height: $switch-height - ($border-width-small * 2);
            border: 0;
            border-radius: $switch-width;
            background: $color-monochrome-100;

            @include mq(medium) {
                left: $border-width-small;
                right: auto;
            }
        }
    }

    /* stylelint-enable */

    &__input {
        // Needs to be position & opacity instead of display for accessibility via keyboard
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        z-index: -1;

        &:focus {
            + .#{$form-selection-control-class-prefix}__label {
                &::before {
                    @include brix-focus(false, 1px);
                }

                .#{$form-selection-control-class-prefix}__item--switch & {
                    .#{$form-selection-control-class-prefix}__text {
                        &::before {
                            width: $switch-hover-circle;
                            height: $switch-hover-circle;
                            right: ($switch-width-25 * 3) - calc($switch-hover-circle / 2) - $border-width-small;  /* 19px */
                            left: auto;

                            @include mq(medium) {
                                right: auto;
                                left: $switch-width-25 - calc($switch-hover-circle / 2) + $border-width-small;  /* -5px */
                            }
                        }
                    }
                }

                @media (hover: hover) {
                    &:hover,
                    &:active {
                        .#{$form-selection-control-class-prefix}__item--switch & {
                            .#{$form-selection-control-class-prefix}__text {
                                &::before {
                                    width: $switch-hover-circle;
                                    height: $switch-hover-circle;
                                    right: ($switch-width-25 * 3) - calc($switch-hover-circle / 2) - $border-width-small;  /* 19px */
                                    left: auto;

                                    @include mq(medium) {
                                        right: auto;
                                        left: $switch-width-25 - calc($switch-hover-circle / 2) + $border-width-small;  /* -5px */
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &:checked {
            + .#{$form-selection-control-class-prefix}__label {
                &::before {
                    border-color: $color-secondary-100;

                    .#{$form-selection-control-class-prefix}__item--checkbox & {
                        background-color: $color-secondary-100;
                    }
                }

                &::after {
                    background-color: $color-secondary-100;
                    width: $inner-circle;
                    height: $inner-circle;

                    .#{$form-selection-control-class-prefix}__item--checkbox & {
                        @include get-icon('tick', $color-monochrome-100);

                        width: $main-circle;
                        height: $main-circle;
                        background-size: $check-icon-size $check-icon-size;
                        background-position: 3px 3px;
                        background-repeat: no-repeat;
                        border-radius: 0;
                    }
                }

                .#{$form-selection-control-class-prefix}__text {
                    &::before {
                        opacity: $opacity-20;
                    }
                }

                .#{$form-selection-control-class-prefix}__item--switch & {
                    &::before {
                        background: $color-secondary-100;
                    }

                    &::after {
                        background-image: none;
                        width: $switch-width - ($border-width-small * 2);
                        height: $switch-height - ($border-width-small * 2);
                        right: $border-width-small;
                        left: auto;

                        @include mq(medium) {
                            right: auto;
                            left: $switch-width-50 - ($border-width-small * 3);  /* 25px */
                        }
                    }

                    .#{$form-selection-control-class-prefix}__text {
                        &::before {
                            right: 0;
                            left: auto;

                            @include mq(medium) {
                                right: auto;
                                left: $switch-width-50 - ($border-width-small * 4);
                            }
                        }
                    }
                }

                @media (hover: hover) {
                    &:hover,
                    &:active {
                        .#{$form-selection-control-class-prefix}__item--switch & {
                            .#{$form-selection-control-class-prefix}__text {
                                &::before {
                                    width: $switch-hover-circle;
                                    height: $switch-hover-circle;
                                    right: $switch-width-25 - calc($switch-hover-circle / 2) - $border-width-small;  /* -5px */
                                    left: auto;

                                    @include mq(medium) {
                                        right: auto;
                                        left: ($switch-width-25 * 3) - calc($switch-hover-circle / 2) - $border-width-small; /* 19px */
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &:disabled {
            + .#{$form-selection-control-class-prefix}__label {
                cursor: not-allowed;

                p {
                    color: $color-monochrome-300;
                }

                &::before {
                    border: 1px solid $color-monochrome-300;

                    .#{$form-selection-control-class-prefix}__item--switch & {
                        border: $zero;
                        background: $color-monochrome-300;
                    }
                }

                &::after {
                    background-color: $color-monochrome-200;
                }

                .#{$form-selection-control-class-prefix}__text {
                    color: $color-monochrome-300;

                    &::before {
                        display: none;
                    }
                }
            }

            &:checked {
                + .#{$form-selection-control-class-prefix}__label::after {
                    background-color: $color-monochrome-300;

                    .#{$form-selection-control-class-prefix}__item--checkbox & {
                        @include get-icon('tick', $color-monochrome-400);

                        background-size: $check-icon-size $check-icon-size;
                        background-position: 3px 3px;
                    }
                }
            }
        }
    }

    &__error {
        .#{$form-selection-control-class-prefix}__input {
            &:enabled {
                + .#{$form-selection-control-class-prefix}__label {
                    p {
                        color: $color-primary-200;
                    }

                    &::before {
                        border: 1px solid $color-primary-200;
                    }

                    &:active {
                        &::before {
                            border-color: $color-primary-200;
                        }
                    }
                }

                &:checked {
                    + .#{$form-selection-control-class-prefix}__label::before {
                        border-color: $color-primary-200;
                        background-color: $color-primary-200;
                    }
                }
            }
        }
        .#{$form-selection-control-class-prefix}__label {
            @media (hover: hover) {
                &:hover,
                &:active {
                    .#{$form-selection-control-class-prefix}__text {
                        &::before {
                            background-color: $color-primary-200;
                            opacity: $opacity-20;
                        }
                    }
                }
            }
        }
    }

    /**
     * I give up with that nesting ;)
     *
     * I removed the svg icons that where inlined in scss.
     * So I reduced a global tech debt
     *
     * I have absolute no idea how to add the next three things into this 470 lines of nesting hell
     *
     */
    &.#{$ws10-css-prefix}-form-selection-control__error
    .#{$ws10-css-prefix}-form-selection-control__item--radio
    .#{$ws10-css-prefix}-form-selection-control__input:enabled:checked + .#{$ws10-css-prefix}-form-selection-control__label::before {
        $really-stylelint: unset;

        background-color: $really-stylelint;
    }

    &.#{$ws10-css-prefix}-form-selection-control__error
    .#{$ws10-css-prefix}-form-selection-control__item--radio
    .#{$ws10-css-prefix}-form-selection-control__input:enabled:checked + .#{$ws10-css-prefix}-form-selection-control__label::after {
        background-color: $color-primary-200;
    }

    & .#{$ws10-css-prefix}-form-selection-control__item--switch .#{$ws10-css-prefix}-form-selection-control__input:checked + .#{$ws10-css-prefix}-form-selection-control__label::after {
        mask: unset;
        mask-size: unset;
        border-radius: 32px;
        background-color: $color-monochrome-100;
    }
}
