/* ==========================================================================
   #OOCSS

   Helper classes to modify looks and behaviours
   Each class has a solid purpose

   INDEX
   - Clearfix
   - Sizes
   - Display
   - Position
   - Float
   - Flexbox
   - Opacity
   - Overflow
   - Padding
   - Margin
   - Border radius
   - Borders
   - Counter Reset (to iterate and number elements automaticly)
   ========================================================================== */

/* Clearfix
========================================================================== */

.#{$ws10-css-prefix}-clearfix {
    &::before,
    &::after {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
    }
}

/* Sizes
   ========================================================================== */

.#{$ws10-css-prefix}-width-100 {
    width: 100%;
}

.#{$ws10-css-prefix}-height-100 {
    height: 100%;
}

/* Display
   ========================================================================== */

.#{$ws10-css-prefix}-block {
    display: block;
}

.#{$ws10-css-prefix}-inline {
    display: inline;
}

.#{$ws10-css-prefix}-inline-block {
    display: inline-block;
}

/* Position
   ========================================================================== */

.#{$ws10-css-prefix}-absolute {
    position: absolute;

    @each $viewport, $val in $breakpoints {
        @include mq($viewport) {
            &--#{$viewport} {
                // stylelint-disable-next-line declaration-no-important
                position: absolute !important;
            }
        }
    }
}

.#{$ws10-css-prefix}-relative {
    position: relative;

    @each $viewport, $val in $breakpoints {
        @include mq($viewport) {
            &--#{$viewport} {
                // stylelint-disable-next-line declaration-no-important
                position: relative !important;
            }
        }
    }
}

.#{$ws10-css-prefix}-fixed {
    position: fixed;

    @each $viewport, $val in $breakpoints {
        @include mq($viewport) {
            &--#{$viewport} {
                // stylelint-disable-next-line declaration-no-important
                position: fixed !important;
            }
        }
    }
}

.#{$ws10-css-prefix}-sticky {
    position: sticky;

    @each $viewport, $val in $breakpoints {
        @include mq($viewport) {
            &--#{$viewport} {
                // stylelint-disable-next-line declaration-no-important
                position: sticky !important;
            }
        }
    }
}

.#{$ws10-css-prefix}-center-horizontal {
    margin-left: auto;
    margin-right: auto;
}

.#{$ws10-css-prefix}-center-vertical {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.#{$ws10-css-prefix}-center-xy {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.#{$ws10-css-prefix}-center-top {
    @include center-top;
}

/* Float
   ========================================================================== */

.#{$ws10-css-prefix}-float-right {
    float: right;
}

.#{$ws10-css-prefix}-float-left {
    float: left;
}

.#{$ws10-css-prefix}-float-none {
    float: none;
}

/* Flexbox
   ========================================================================== */

.#{$ws10-css-prefix}-flex {
    display: flex;
}

.#{$ws10-css-prefix}-flex-row-reverse {
    flex-direction: row-reverse;
}

.#{$ws10-css-prefix}-flex-column {
    flex-direction: column;
}

.#{$ws10-css-prefix}-flex-column-reverse {
    flex-direction: column-reverse;
}

.#{$ws10-css-prefix}-flex-wrap {
    flex-wrap: wrap;
}

.#{$ws10-css-prefix}-flex-nowrap {
    flex-wrap: nowrap;
}

.#{$ws10-css-prefix}-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.#{$ws10-css-prefix}-flex-start {
    justify-content: flex-start;
    -ms-flex-pack: start;
}

.#{$ws10-css-prefix}-flex-end {
    justify-content: flex-end;
    -ms-flex-pack: end;
}

.#{$ws10-css-prefix}-flex-center {
    justify-content: center;
    -ms-flex-pack: center;
}

.#{$ws10-css-prefix}-flex-between {
    justify-content: space-between;
    -ms-flex-pack: justify;
}

.#{$ws10-css-prefix}-flex-around {
    justify-content: space-around;
    -ms-flex-pack: justify;
}

.#{$ws10-css-prefix}-flex-stretch {
    justify-content: stretch;
    -ms-flex-pack: distribute;
}

.#{$ws10-css-prefix}-flex-align-top {
    align-items: flex-start;
    -ms-flex-align: start;
}

.#{$ws10-css-prefix}-flex-align-end {
    align-items: flex-end;
    -ms-flex-align: end;
    align-content: flex-end;
}

.#{$ws10-css-prefix}-flex-align-center {
    align-items: center;
    -ms-flex-align: center;
    align-content: center;
}

.#{$ws10-css-prefix}-flex-align-stretch {
    align-items: stretch;
    -ms-flex-align: stretch;
    align-content: stretch;
}

.#{$ws10-css-prefix}-flex-align-baseline {
    align-items: baseline;
    -ms-flex-align: baseline;
    align-content: baseline;
}

.#{$ws10-css-prefix}-flex-align-start {
    align-content: flex-start;
}

.#{$ws10-css-prefix}-flex-align-self-start {
    align-self: flex-start;
}

.#{$ws10-css-prefix}-flex-align-self-end {
    align-self: flex-end;
}

.#{$ws10-css-prefix}-flex-align-self-center {
    align-self: center;
}

.#{$ws10-css-prefix}-flex-align-self-stretch {
    align-self: stretch;
}

.#{$ws10-css-prefix}-flex-align-self-baseline {
    align-self: baseline;
}

/* Opacity
   ========================================================================== */

.#{$ws10-css-prefix}-opacity-0 {
    opacity: 0;
}

.#{$ws10-css-prefix}-opacity-10 {
    opacity: 10%;
}

.#{$ws10-css-prefix}-opacity-20 {
    opacity: 20%;
}

.#{$ws10-css-prefix}-opacity-100 {
    opacity: 1;
}

/* Overflow
   ========================================================================== */

.#{$ws10-css-prefix}-overflow-hidden {
    overflow: hidden;
}

.#{$ws10-css-prefix}-overflow-visible {
    overflow: visible;
}

/* Padding
   ========================================================================== */

.#{$ws10-css-prefix}-pad-0 {
    padding: 0;
}

.#{$ws10-css-prefix}-pad-gutter {
    padding: $space-100;
}

.#{$ws10-css-prefix}-pad-gutter-0 {
    padding: $space-100 0;
}

.#{$ws10-css-prefix}-pad-0-gutter {
    padding: $zero $space-100;
}

.#{$ws10-css-prefix}-pad-gutter-doubled {
    padding: $space-200;
}

.#{$ws10-css-prefix}-pad-gutter-one-plus-half {
    padding: $space-150;
}

.#{$ws10-css-prefix}-pad-gutter-three-quarter {
    padding: $space-75;
}

.#{$ws10-css-prefix}-pad-gutter-half {
    padding: $space-50;
}

.#{$ws10-css-prefix}-pad-gutter-quarter {
    padding: $space-25;
}

/* Margin
   ========================================================================== */

.#{$ws10-css-prefix}-mar-0 {
    margin: 0;
}

.#{$ws10-css-prefix}-mar-gutter {
    margin: $space-100;
}

.#{$ws10-css-prefix}-mar-gutter-200-0 {
    margin: $space-200 0;
}

.#{$ws10-css-prefix}-mar-gutter-half {
    margin: $space-50;
}

.#{$ws10-css-prefix}-mar-top-gutter {
    margin-top: $space-100;
}

.#{$ws10-css-prefix}-mar-top-gutter-half {
    margin-top: $space-50;
}

.#{$ws10-css-prefix}-mar-bottom-gutter {
    margin-bottom: $space-100;
}

.#{$ws10-css-prefix}-mar-gutter-0 {
    margin: $space-100 0;
}

.#{$ws10-css-prefix}-mar-0-gutter {
    margin: 0 $space-100;
}

.#{$ws10-css-prefix}-mar-gutter-one-plus-half {
    margin: $space-150;
}

/* Border radius
   ========================================================================== */

.#{$ws10-css-prefix}-radius-default {
    border-radius: $border-radius-small;
}

.#{$ws10-css-prefix}-radius-large {
    border-radius: $border-radius-large;
}

.#{$ws10-css-prefix}-radius-pill {
    border-radius: $border-radius-pill;
}

.#{$ws10-css-prefix}-radius-half {
    border-radius: $border-radius-half;
}

/* Borders
   ========================================================================== */

.#{$ws10-css-prefix}-border-dashed-grey {
    border: $border-dashed-grey;
}

.#{$ws10-css-prefix}-border-solid-grey {
    border: $border-solid-grey;
}

.#{$ws10-css-prefix}-border-bottom-solid-grey {
    border: $border-solid-grey;
}

.#{$ws10-css-prefix}-border-s {
    border: $border-small;
}

.#{$ws10-css-prefix}-border-m {
    border: $border-medium;
}

.#{$ws10-css-prefix}-border-l {
    border: $border-large;
}

.#{$ws10-css-prefix}-border-xl {
    border: $border-x-large;
}

.#{$ws10-css-prefix}-border-xxl {
    border: $border-xx-large;
}

/* Button - @TODO: move to button component when we have one
   ========================================================================== */

.#{$ws10-css-prefix}-btn--reset {
    padding: 0;
    border: 0;
    background-color: transparent;
}

.#{$ws10-css-prefix}-fade {
    opacity: 0;
    transition: opacity .5s;

    &.#{$ws10-css-prefix}-in {
        opacity: 1;
    }
}

.#{$ws10-css-prefix}-slide {
    transition: max-height $duration-500 $ease-in-out-sine;
}

/* Counter Reset
   ========================================================================== */
.#{$ws10-css-prefix}-counter-stepper {
    counter-reset: stepper;
}

/* HR separator
   ========================================================================== */
.#{$ws10-css-prefix}-separator {
    border-bottom: $border-solid-grey;
    border-top: 0;
}
