// import only precss from styles package! This contains all your variables you need!
@use 'sass:map';
@import '../styles/scss/precss/precss';

$button-icon-only-class-prefix: 'ws10-button-icon-only';

// These need to be in this file - otherwise they are not exported with the package
// stylelint-disable-next-line value-list-max-empty-lines
$button-color-variations: (
    primary: (
        /* icon color*/
        icon: $color-monochrome-100,
        /* container color*/
        container: $color-primary-100,
        /* hover, press and focus color*/
        hover: hover($color-primary-100),
        /* active/press color*/
        active: press($color-primary-100),
        /* cursor on hovering*/
        cursor: pointer,

    ),
    primary-inverse: (
        /* icon color*/
        icon: $color-monochrome-600-inverse,
        /* container color*/
        container: $color-primary-100-inverse,
        /* hover color*/
        hover: hover($color-primary-100-inverse),
        /* active/press color*/
        active: press($color-primary-100-inverse),
        /* cursor on hovering*/
        cursor: pointer,
    ),
    secondary: (
        /* icon color*/
        icon: $color-monochrome-100,
        /* container color*/
        container: $color-monochrome-600,
        /* hover, press and focus color*/
        hover: hover($color-monochrome-600),
        /* active/press color*/
        active: press($color-monochrome-600),
        /* cursor on hovering*/
        cursor: pointer,

    ),
    secondary-inverse: (
        /* icon color*/
        icon: $color-monochrome-100-inverse,
        /* container color*/
        container: $color-monochrome-600-inverse,
        /* hover, press and focus color*/
        hover: hover($color-monochrome-600-inverse),
        /* active/press color*/
        active: press($color-monochrome-600-inverse),
        /* cursor on hovering*/
        cursor: pointer,
    ),
    tertiary: (
        /* icon color*/
        icon: $color-monochrome-600,
        /* container color*/
        container: transparent,
        /* hover, press and focus color*/
        hover: hover($color-monochrome-600),
        /* hover, press and focus color for icon*/
        hovericon: $color-monochrome-100,
        /* active/press color*/
        active: press($color-monochrome-600),
        /* cursor on hovering*/
        cursor: pointer,
        /* border*/
        border: $border-width-medium $border-style-solid $color-monochrome-600,
        /* border when btn is disabled */
        borderdisabled: $border-width-medium $border-style-solid $color-monochrome-300,

    ),
    tertiary-inverse: (
        /* icon color*/
        icon: $color-monochrome-600-inverse,
        /* container color*/
        container: transparent,
        /* hover, press and focus color*/
        hover: hover($color-monochrome-600-inverse),
        /* active/press color*/
        active: press($color-monochrome-600-inverse),
        /* hover, press and focus color for icon*/
        hovericon: $color-monochrome-100-inverse,
        /* cursor on hovering*/
        cursor: pointer,
        /* border */
        border: $border-width-medium $border-style-solid $color-monochrome-600-inverse,
        /* border when haover btn */
        borderhover: $border-width-medium $border-style-solid hover($color-monochrome-600-inverse),
        /* border when active btn*/
        borderactive: $border-width-medium $border-style-solid press($color-monochrome-600-inverse),
        /* border when btn is disabled */
        borderdisabled: $border-width-medium $border-style-solid $color-monochrome-300,
    ),
    disabled: (
        /* icon color*/
        icon: $color-monochrome-400,
        /* container color*/
        container:$color-monochrome-300,
        /* hover color*/
        hover: $color-monochrome-300,
        /* active/press color*/
        active: $color-monochrome-300,
        /* cursor on hovering*/
        cursor: not-allowed,

    ),
    disabled-inverse: (
        /* icon color*/
        icon: $color-monochrome-400-inverse,
        /* container color*/
        container:$color-monochrome-300-inverse,
        /* hover, press and focus color*/
        hover: $color-monochrome-300-inverse,
        /* active/press color*/
        active: $color-monochrome-300-inverse,
        /* cursor on hovering*/
        cursor: not-allowed,

    ),
    transparent: (
        /* icon color*/
        icon: $color-primary-200,
        /* container color*/
        container: transparent,
        /* hover, press and focus color*/
        hover: hover($color-primary-100),
        /* cursor on hovering*/
        cursor: pointer,
        /* hover, press and focus color for icon*/
        hovericon: $color-monochrome-100,
    ),
);
$button-shape-variations: (
    square: (
        radius: $border-radius-medium,
    ),
    circle: (
        radius: $border-radius-half,
    ),
    floating: (
        radius: $border-radius-half,
        shadow: $shadow-large,
    ),
    transparent: (
        radius: $border-radius-half,
    )
);
$button-size-variation: (
    standard: (
        size: $size-button-large,
        mobile: $size-button-medium,
    ),
    large: (
        size: $size-button-x-large,
        mobile: $size-button-large,
    )
);

// mobile measurements by default
// include mq(small) for desktop
.#{$button-icon-only-class-prefix} {
    @include ws10-text(false);

    display: flex;
    justify-content: center;
    align-items: center;

    // icon is 150 for mobile and 200 for desktop in every size variations
    &__icon {
        width: $size-icon-150;
        height: $size-icon-150;

        @include mq(small) {
            width: $size-icon-200;
            height: $size-icon-200;
        }
    }

    // color variations
    @each $name, $variation in $button-color-variations {
        &--#{"" + $name} {
            background: map.get($variation, container);
            border: map.get($variation, border);

            .#{$button-icon-only-class-prefix}__icon {
                color: map.get($variation, icon);
            }

            @media (hover: hover) {
                /* stylelint-disable-next-line selector-not-notation */
                &:hover:not(:active):not(:disabled),
                &:focus-visible:not([disabled]) {
                    background-color: map.get($variation, hover);
                    border: map.get($variation, borderhover);
                    cursor: map.get($variation, cursor);

                    .#{$button-icon-only-class-prefix}__icon {
                        color: map.get($variation, hovericon);
                    }
                }
            }

            &:active:not([disabled]) {
                background: map.get($variation, active);
                cursor: map.get($variation, cursor);
                border: map.get($variation, borderactive);
                .#{$button-icon-only-class-prefix}__icon {
                    color: map.get($variation, hovericon);
                }
            }

            &:disabled {
                border: map.get($variation, borderdisabled);
                cursor: map.get($variation, cursor);
            }

            // enabling tab-only focus
            &:focus-visible:not([disabled]) {
                @include brix-focus(false);

                background-color: map.get($variation, hover);
                border: map.get($variation, borderhover);
                cursor: map.get($variation, cursor);

                .#{$button-icon-only-class-prefix}__icon {
                    color: map.get($variation, hovericon);
                }
            }
        }
    }

    // size variations (standard and large for both mobile and desktop)
    @each $name, $variation in $button-size-variation {
        &--#{$name} {
            width: map.get($variation, mobile);
            height: map.get($variation, mobile);

            @include mq(small) {
                width: map.get($variation, size);
                height: map.get($variation, size);
            }
        }
    }

    // shape variation
    @each $name, $variation in $button-shape-variations {
        &--#{"" + $name} {
            border-radius: map.get($variation, radius);
            box-shadow: map.get($variation, shadow);
        }
    }
}

// special cases
.#{$button-icon-only-class-prefix}--tertiary.#{$button-icon-only-class-prefix}--floating:not(:hover):not(:active):not(:focus) {
    background: $color-monochrome-100;
}
