.#{$ws10-css-prefix}-bullet-list {
    @include basic-list-style;
}

.#{$ws10-css-prefix}-numbered-list {
    @include basic-list-style(decimal);

    &--bold {
        li {
            &::first-line,
            &::marker {
                font-weight: bold;
            }
        }
    }
}

.#{$ws10-css-prefix}-bullet-list,
.#{$ws10-css-prefix}-numbered-list,
.#{$ws10-css-prefix}-hook-list,
.#{$ws10-css-prefix}-checkmark-list,
.#{$ws10-css-prefix}-cross-list {
    &-white {
        color: $color-monochrome-100;
    }
}

.#{$ws10-css-prefix}-hook-list,
.#{$ws10-css-prefix}-checkmark-list {
    @include basic-list-style(none);

    padding-left: $space-150 + $space-25;

    li {
        position: relative;

        * {
            fill: none;
            stroke: none;
        }

        &::before {
            position: absolute;
            display: inline-block;
            content: '';
            left: -($space-150 + $space-25);
            top: $zero;
            width: $size-icon-150;
            height: $size-icon-150;

            @include get-icon('tick', $color-monochrome-600);
        }
    }

    &-white {
        li {
            &::before {
                @include get-icon('tick', $color-monochrome-100);
            }
        }
    }
}

.#{$ws10-css-prefix}-hook-list {
    li {
        &::before {
            @include get-icon('tick', $color-secondary-500);
        }
    }

    &-white {
        li {
            &::before {
                @include get-icon('tick', $color-monochrome-100);
            }
        }
    }
}

.#{$ws10-css-prefix}-cross-list {
    @include basic-list-style(none);

    padding-left: $space-150 + $space-25;

    li {
        position: relative;

        &::before {
            position: absolute;
            display: inline-block;
            content: '';
            left: -($space-150 + $space-25);
            top: $zero;
            width: $size-icon-150;
            height: $size-icon-150;

            @include get-icon('close', $color-primary-200);
        }
    }

    &-white {
        li {
            &::before {
                @include get-icon('close', $color-monochrome-100);
            }
        }
    }
}
