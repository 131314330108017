/* ==========================================================================
   #Table
   ========================================================================== */

/* stylelint-disable max-nesting-depth */

/* Global table class
 */

.#{$ws10-css-prefix}-table-theme {
    &--default {
        overflow-x: auto;

        table {
            display: table;
            width: 100%;
            font-size: $font-size-50;
            line-height: $line-height-100;
            font-weight: $font-weight-normal;
            color: $color-monochrome-600;

            thead {
                display: table-header-group;
                background: $color-monochrome-200;
                border-bottom: 2px solid $color-monochrome-400;

                tr {
                    display: table-row;
                }

                th,
                td {
                    display: table-cell;
                    padding: $space-100 $space-75;
                    font-weight: $font-weight-bold;
                }

                tr:first-child {
                    border-bottom: 2px solid $color-primary-100;
                }
            }

            tbody {
                tr {
                    display: table-row;
                    border-bottom: 1px solid $color-monochrome-200;
                }

                td {
                    display: table-cell;
                    padding: $space-100 $space-75;
                    border-right: 1px solid $color-monochrome-200;

                    &:last-child {
                        border-right: 0;
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

/* stylelint-enable */
