/* ==========================================================================
    #Miscelaneous styles
   ========================================================================== */

/* Clearing variables
   ========================================================================== */

$inherit: inherit;
$zero: 0;
$transparent: transparent;

/* Outlines
   ========================================================================== */

$anchor-outline: 3px solid $color-secondary-100;

/* Sizing
   ========================================================================== */

$full-percent: 100%;
$half-percent: 50%;
$one-third-percent: calc(100% / 3);
