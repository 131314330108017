@import '../styles/scss/precss/precss';

$tooltip-class-prefix: $ws10-css-prefix + '-tooltip';

.#{$tooltip-class-prefix} {
    @include ws10-text(false);

    position: relative;
    display: none;
    margin-left: $space-50;

    &__trigger {
        cursor: pointer;

        &:focus-visible {
            @include brix-focus;
        }

        &:focus {
            outline: none;
        }
    }

    &-bubble {
        position: absolute;
        top: -500px;
        left: -500px;
        z-index: 70;
        background: $color-monochrome-100;
        max-width: 320px;
        border-radius: $border-radius-medium;
        box-shadow: $shadow-large;
        font-weight: $font-weight-normal;
        padding: $space-100 $space-125;
        outline: none;

        &__headline {
            @include ws10-h5;

            font-size: $font-size-200;
        }

        &__content {
            font-size: $font-size-100;
            line-height: 1.5;
            color: $color-monochrome-600;

            @include mq(small) {
                font-size: $font-size-150;
            }

            p {
                @include ws10-text;

                font-size: inherit;
                color: inherit;

                // Workaround: for some reason no-spacing argument does not work
                margin-bottom: $space-0;
            }

            ul,
            ol {
                @include basic-list-style;

                li {
                    font-size: inherit;
                }
            }
        }

        &--small {
            padding: $space-50 $space-100;
            background: $color-monochrome-500;

            .#{$tooltip-class-prefix}-bubble__content {
                color: $color-monochrome-100;
            }
        }
    }

    &-nose {
        $brix-tooltip-nose-size: 16px;

        position: absolute;
        padding: 0;
        top: -500px;
        left: -500px;
        width: $brix-tooltip-nose-size * 1.5;
        height: $brix-tooltip-nose-size;
        z-index: 71;
        overflow: hidden;
        pointer-events: none;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: -9px;
            left: 3px;
            width: $brix-tooltip-nose-size;
            height: $brix-tooltip-nose-size;
            background-color: $color-monochrome-100;
            transform: rotate(45deg);
        }

        &--small {
            &::before {
                background: $color-monochrome-500;
            }

            &--flipped {
                &::before {
                    top: 6px;
                }
            }
        }

        &--sideleft {
            width: $brix-tooltip-nose-size;
            height: $brix-tooltip-nose-size * 1.5;

            &::before {
                top: 5px;
                left: -8px;
            }
        }

        &--sideright {
            width: $brix-tooltip-nose-size;
            height: $brix-tooltip-nose-size * 1.5;

            &::before {
                top: 3px;
                left: 7px;
            }
        }

        &--flipped {
            &::before {
                top: 6px;
            }
        }
    }
}

.#{$tooltip-class-prefix}-fade {
    opacity: 0;
    transition: opacity $duration-200 $ease-in-out-circ;

    &.#{$tooltip-class-prefix}-fade-in {
        opacity: 1;
    }
}
