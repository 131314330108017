@import '../styles/scss/precss/precss';
@import '../icon/icon';

$list-card-class-prefix: $ws10-css-prefix + '-list-card';

.#{$list-card-class-prefix} {
    @include ws10-text(false);

    margin-bottom: $space-100;

    &__item {
        @include card-base;

        display: grid;
        grid-template-columns: auto 1fr;
        // stylelint-disable-next-line declaration-no-important
        text-decoration: none !important;
        box-shadow: $shadow-medium;

        &:hover {
            box-shadow: $shadow-small;
            position: relative;
            z-index: 2;
        }

        &:active {
            box-shadow: $shadow-x-small;
            position: relative;
            z-index: 2;
        }

        &:focus-visible {
            @include brix-focus;
        }

        &:focus {
            outline: none;
        }

        &__icon {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            width: $size-icon-300;
            height: $size-icon-300;
            align-self: center;

            @include mq(medium) {
                width: $size-icon-350;
                height: $size-icon-350;
            }

            @include mq(large) {
                grid-column: 1 / 2;
                grid-row: 1 / 4;
                width: $size-icon-400;
                height: $size-icon-400;
                align-self: start;
                display: table;
                padding-right: $space-100;
            }
        }

        &__headline {
            grid-column: 2 / 3;
            grid-row: 1 / 2;

            @include ws10-text;
            @include ws10-h5;

            color: $color-monochrome-600;
            padding: $space-0 $space-50;
            align-self: center;
            margin-bottom: 0;

            @include mq(large) {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                padding: $space-0;
                margin-bottom: $space-0;

                @include ws10-h4;
            }
        }

        &__text {
            grid-column: 1 / 4;
            grid-row: 2 / 2;

            @include ws10-text;

            padding-top: $space-75;
            margin-bottom: $space-0;
            align-self: center;

            @include mq(large) {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
                padding: $space-50 $space-0;
            }
        }

        &__more-info {
            grid-column: 3 / 4;
            grid-row: 1 / 2;
            color: $color-primary-200;
            align-self: center;

            @include mq(large) {
                grid-column: 2 / 3;
                grid-row: 3 / 4;
                padding-bottom: $space-15;

                &__container {
                    display: inline-flex;
                    border-bottom: $border-width-small $border-style-solid transparent;
                    transition: color $duration-200 $ease-in-sine, border $duration-200 $ease-in-sine;
                }

                &__container:hover {
                    border-bottom: $border-width-small $border-style-solid $color-primary-200;
                    color: $color-primary-200;
                }
            }

            &__text {
                display: none;

                @include mq(large) {
                    @include ws10-text;

                    display: inline;
                    color: $color-primary-200;
                    padding-right: $space-25;
                    margin-bottom: $space-0;
                }
            }

            &__icon {
                width: $size-icon-200;
                height: $size-icon-200;

                @include mq(large) {
                    width: $size-icon-150;
                    height: $size-icon-150;
                }
            }
        }
    }

    & > a:not(:first-child) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    & > a:not(:last-child) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
