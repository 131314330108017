/* ==========================================================================
   #STATES
   All classes here have a verb and describe a behaviour
   Disabled linter because this file will always have warnings for the !important
   ========================================================================== */

/* stylelint-disable declaration-no-important */

.#{$ws10-css-prefix}-is-hidden {
    display: none !important;
}

.#{$ws10-css-prefix}-is-visually-hidden {
    visibility: hidden !important;
}

.#{$ws10-css-prefix}-is-visible-block {
    display: block !important;
}

.#{$ws10-css-prefix}-mobile-hide {
    @include mq(mobile-only) {
        display: none !important;
    }
}

.#{$ws10-css-prefix}-tablet-up-hide {
    display: block;

    @include mq(large) {
        display: none !important;
    }
}

// Hide content visually while keeping it accessible to assistive technologies
.#{$ws10-css-prefix}-sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: #{($space-0 - 1)} !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

/* stylelint-enable */
