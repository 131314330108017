@import '../styles/scss/precss/precss';

$system-icon-prefix: 'ws10-system-icon';

.#{$system-icon-prefix} {
    @include ws10-text(false);

    &--size-100 {
        width: $size-icon-100;
        height: $size-icon-100;
    }

    &--size-125 {
        width: $size-icon-125;
        height: $size-icon-125;
    }

    &--size-150 {
        width: $size-icon-150;
        height: $size-icon-150;
    }

    &--size-200 {
        width: $size-icon-200;
        height: $size-icon-200;
    }

    &--size-225 {
        width: $size-icon-225;
        height: $size-icon-225;
    }

    &--color-primary-100 {
        color: $color-primary-100;
    }

    &--color-primary-100-inverse {
        color: $color-primary-100-inverse;
    }

    &--color-primary-200 {
        color: $color-primary-200;
    }

    &--color-primary-200-inverse {
        color: $color-primary-200-inverse;
    }

    &--color-monochrome-100 {
        color: $color-monochrome-100;
    }

    &--color-monochrome-100-inverse {
        color: $color-monochrome-100-inverse;
    }

    &--color-monochrome-300 {
        color: $color-monochrome-300;
    }

    &--color-monochrome-300-inverse {
        color: $color-monochrome-300-inverse;
    }

    &--color-monochrome-400 {
        color: $color-monochrome-400;
    }

    &--color-monochrome-400-inverse {
        color: $color-monochrome-400-inverse;
    }

    &--color-monochrome-600 {
        color: $color-monochrome-600;
    }

    &--color-monochrome-600-inverse {
        color: $color-monochrome-600-inverse;
    }

    &--color-secondary-500 {
        color: $color-secondary-500;
    }

    &--color-inherit {
        color: inherit;
    }
}
