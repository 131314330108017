@use 'sass:map';

/* ==========================================================================
   #MIXINS
   ========================================================================== */

/**
 * Get Icon. Returns icon as background image
 */

@mixin get-icon($icon-name, $color) {
    $url: get-icon-path($icon-name);

    mask: url($url) no-repeat 50% 50%;
    mask-size: cover;
    background-color: $color;
}

@function get-icon-path($icon-name) {
    @if $core-styles-in-project {
        @return '~@vfde-brix/icons/dist/svg/' + $icon-name + '.svg';
    }

    @return '/simplicity/icons/' + $icon-name + '.svg';
}

/**
 * H1-5 $font-size-400
 */

@mixin font-style($font-sizes, $font-weight, $line-height: $line-height-50, $bottom-spaces: ('x-small': 0)) {
    font-weight: $font-weight;
    line-height: $line-height;
    color: $color-monochrome-600;

    @each $mq, $size in $font-sizes {
        @include mq($mq) {
            font-size: $size;
        }
    }

    @each $mq, $space in $bottom-spaces {
        @if $mq == 'x-small' {
            margin-bottom: $space;
        }
        @else {
            @include mq($mq) {
                margin-bottom: $space;
            }
        }
    }
}

@mixin ws10-h1() {
    $font-sizes: (
        'x-small': $font-size-500,
        'small': $font-size-700,
        'large': $font-size-750,
    );
    $bottom-spaces: (
        'x-small': $space-125,
        'large': $space-150,
    );

    @include font-style($font-sizes, $font-weight-light, $line-height-50, $bottom-spaces);
}

@mixin ws10-h1-inverse() {
    @include ws10-h1;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-h2() {
    $font-sizes: (
        'x-small': $font-size-400,
        'small': $font-size-500
    );
    $bottom-spaces: (
        'x-small': $space-100,
        'small': $space-125,
    );

    @include font-style($font-sizes, $font-weight-light, $line-height-50, $bottom-spaces);
}

@mixin ws10-h2-inverse() {
    @include ws10-h2;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-h2-bold() {
    @include ws10-h2;

    font-weight: $font-weight-bold;
}

@mixin ws10-h2-bold-inverse() {
    @include ws10-h2-inverse;

    font-weight: $font-weight-bold;
}

@mixin ws10-h3() {
    $font-sizes: (
        'x-small': $font-size-300,
        'small': $font-size-400,
    );
    $bottom-spaces: (
        'x-small': $space-100,
        'large': $space-75,
    );

    @include font-style($font-sizes, $font-weight-bold, $line-height-50, $bottom-spaces);
}

@mixin ws10-h3-inverse() {
    @include ws10-h3;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-h4() {
    $font-sizes: (
        'x-small': $font-size-200,
        'small': $font-size-300,
    );
    $bottom-spaces: (
        'x-small': $space-50,
    );

    @include font-style($font-sizes, $font-weight-bold, $line-height-50, $bottom-spaces);
}

@mixin ws10-h4-inverse() {
    @include ws10-h4;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-h5() {
    $font-sizes: (
        'x-small': $font-size-150,
        'small': $font-size-200
    );
    $bottom-spaces: (
        'x-small': $space-50,
    );

    @include font-style($font-sizes, $font-weight-bold, $line-height-50, $bottom-spaces);
}

@mixin ws10-h5-inverse() {
    @include ws10-h5;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-text($set-bottom-spaces: true) {
    $font-sizes: (
        'x-small': $font-size-100,
        'small': $font-size-150,
    );
    $bottom-spaces: null;

    @if $set-bottom-spaces {
        $bottom-spaces: (
            'x-small': $space-100,
        );
    }
    @else {
        $bottom-spaces: ();
    }

    @include font-style($font-sizes, $font-weight-normal, $line-height-100, $bottom-spaces);
}

@mixin ws10-text-inverse() {
    @include ws10-text;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-text-large() {
    $font-sizes: (
        'x-small': $font-size-150,
        'small': $font-size-200,
    );

    @include font-style($font-sizes, $font-weight-normal, $line-height-100);
}

@mixin ws10-text-large-inverse() {
    @include ws10-text-large;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-text-small() {
    $font-sizes: (
        'x-small': $font-size-100,
        'small': $font-size-100,
    );

    @include font-style($font-sizes, $font-weight-normal, $line-height-100);
}

@mixin ws10-text-small-inverse() {
    @include ws10-text-small;

    color: $color-monochrome-600-inverse;
}

@mixin ws10-text-smaller() {
    $font-sizes: (
        'x-small': $font-size-50,
        'small': $font-size-50,
    );

    @include font-style($font-sizes, $font-weight-normal, $line-height-100);
}

@mixin ws10-text-smaller-inverse() {
    @include ws10-text-smaller;

    color: $color-monochrome-600-inverse;
}

@mixin focus-outline() {
    @media (hover: hover) {
        box-shadow: $focus-outline;
    }
}

@mixin ws10-text-link($default-color: $color-monochrome-600, $hover-color: $color-primary-200, $disabled-color: $color-monochrome-300) {
    color: $default-color;

    &:focus,
    &:active {
        color: $hover-color;
    }

    @media (hover: hover) {
        &:hover {
            color: $hover-color;
        }
    }

    @if $disabled-color != none {
        &__disabled,
        :disabled {
            // stylelint-disable-next-line declaration-no-important
            color: $disabled-color !important;
        }
    }
}

@mixin reset-element {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: $full-percent;
    vertical-align: baseline;
}

@mixin reset-list {
    list-style: none;
}

/* Media Queries
 * ==========================================================================
 * only min-width querys (Mobile First)!
 * @param {variable} $size [e.g. x-small, medium large] - Desired viewport visualization
 *
 * @example
 * @include mq(large) { color: $white; }
 *
 */
@mixin mq($size) {
    @if $size == x-small {
        @content;
    }
    @else if $size == mobile-only {
        @media screen and (max-width: #{map.get($breakpoints, 'small') - 1px}) {
            @content;
        }
    }
    @else {
        @media screen and (min-width: map.get($breakpoints, $size)) {
            @content;
        }
    }
}

/**
 * @TODO Add desc
 */
@mixin center-top() {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
}

/* Linear Gradients
 * ==========================================================================
 *
 *  @param {variable} $angle ["to right" | "bottom" | 45deg | ...] - Direction of gradient.
 *  @param {variable} $color-stops [list] - List of colors
 *
 *  @example
 *  @include linear-gradient("left", $gradient-01);
 *  @include linear-gradient("to right", $gradient-01);
 *  @include linear-gradient(45deg, $gradient-02);
 */

@mixin linear-gradient($angle, $color-stops) {
    $angle-with-vendor-prefix: '';
    $angle: '';

    @if $angle == 'to top' or $angle == 'bottom' {
        $angle-with-vendor-prefix: bottom;
        $angle: to top;
    }
    @else if $angle == 'to right' or $angle == 'left' {
        $angle-with-vendor-prefix: left;
        $angle: to right;
    }
    @else if $angle == 'to bottom' or $angle == 'top' {
        $angle-with-vendor-prefix: top;
        $angle: to bottom;
    }
    @else if $angle == 'to left' or $angle == 'right' {
        $angle-with-vendor-prefix: right;
        $angle: to left;
    }
    @else if $angle == 'to top right' or $angle == 'bottom left' {
        $angle-with-vendor-prefix: bottom left;
        $angle: to top right;
    }
    @else if $angle == 'to bottom right' or $angle == 'top left' {
        $angle-with-vendor-prefix: top left;
        $angle: to bottom right;
    }
    @else if $angle == 'to bottom left' or $angle == 'top right' {
        $angle-with-vendor-prefix: top right;
        $angle: to bottom left;
    }
    @else if $angle == 'to top left' or $angle == 'bottom right' {
        $angle-with-vendor-prefix: bottom right;
        $angle: to top left;
    }
    @else {
        $angle-with-vendor-prefix: $angle % 360;
        $angle: (90 - $angle) % 360;
    }

    background: linear-gradient($angle, $color-stops);
}

@mixin basic-link-style() {
    text-decoration: underline;
    display: inline-block;

    &:focus-visible {
        @include focus-outline;
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &__disabled {
        cursor: not-allowed;

        &:focus,
        &:active {
            // stylelint-disable-next-line declaration-no-important
            text-decoration: underline !important;
        }

        @media (hover: hover) {
            &:hover {
                // stylelint-disable-next-line declaration-no-important
                text-decoration: underline !important;
            }
        }

        &:focus {
            // stylelint-disable-next-line declaration-no-important
            box-shadow: none !important;
        }
    }
}

@mixin basic-list-style($type: disc) {
    @include ws10-text;

    margin-bottom: $space-50;
    padding-left: $space-150;
    list-style-type: $type;

    li {
        margin-bottom: $space-50;
    }

    a {
        @include basic-link-style;
        @include ws10-text-link;

        &:has(> sup) {
            text-decoration: none;
        }
    }
}

@mixin card-base() {
    border-radius: $border-radius-medium;
    box-shadow: $shadow-medium;
    background-color: $color-monochrome-100;
    padding: $space-100;

    @include mq(small) {
        padding: $space-125;
    }
}

@mixin set-custom-property($name, $value) {
    --#{$ws10-css-prefix}-#{$name}: #{$value};
}

@function get-custom-property($name, $defaultValue: false) {
    $property-name: #{$ws10-css-prefix}-#{$name};

    @if $defaultValue {
        @return var(--#{$property-name}, $defaultValue);
    }

    @return var(--#{$property-name});
}
