/* Body text
   ==========================================================================
*/
p,
.#{$ws10-css-prefix}-text {
    @include ws10-text;
}
.#{$ws10-css-prefix}-text-inverse {
    @include ws10-text-inverse;
}

.#{$ws10-css-prefix}-text-large {
    @include ws10-text-large;
}
.#{$ws10-css-prefix}-text-large-inverse {
    @include ws10-text-large-inverse;
}

.#{$ws10-css-prefix}-text-small {
    @include ws10-text-small;
}
.#{$ws10-css-prefix}-text-small-inverse {
    @include ws10-text-small-inverse;
}

.#{$ws10-css-prefix}-text-smaller {
    @include ws10-text-smaller;
}
.#{$ws10-css-prefix}-text-smaller-inverse {
    @include ws10-text-smaller-inverse;
}

.#{$ws10-css-prefix}-text-strike {
    text-decoration: $text-decoration-strike-through;
}

.#{$ws10-css-prefix}-text-italic {
    font-style: italic;
}

.#{$ws10-css-prefix}-text-bold {
    font-weight: $font-weight-bold;
}

.#{$ws10-css-prefix}-text-light {
    font-weight: $font-weight-light;
}

.#{$ws10-css-prefix}-uppercase {
    text-transform: uppercase;
}

.#{$ws10-css-prefix}-bold-positive {
    color: $color-secondary-500;
    font-weight: $font-weight-bold;
}
.#{$ws10-css-prefix}-text-inverse.#{$ws10-css-prefix}-bold-positive,
.#{$ws10-css-prefix}-bold-positive-inverse {
    color: $color-secondary-500-inverse;
    font-weight: $font-weight-bold;
}

.#{$ws10-css-prefix}-text-left {
    text-align: left;
}

.#{$ws10-css-prefix}-text-center {
    text-align: center;
}

.#{$ws10-css-prefix}-text-justify {
    text-align: justify;
}

.#{$ws10-css-prefix}-prevent-decoration {
    // stylelint-disable-next-line declaration-no-important
    text-decoration: none !important;
}

@each $viewport, $val in $breakpoints {
    @include mq($viewport) {
        .#{$ws10-css-prefix}-text-center--#{$viewport} {
            text-align: center;
        }
    }
}
