.#{$ws10-css-prefix}-text a,
.#{$ws10-css-prefix}-text-link,
.#{$ws10-css-prefix}-text-inverse a,
.#{$ws10-css-prefix}-text-link-inverse,
.#{$ws10-css-prefix}-footnote-link,
.#{$ws10-css-prefix}-footnote-link-inverse {
    @include basic-link-style;
}

.#{$ws10-css-prefix}-text a,
.#{$ws10-css-prefix}-text-link {
    @include ws10-text-link;
}

.#{$ws10-css-prefix}-text-inverse a,
.#{$ws10-css-prefix}-text-link-inverse {
    @include ws10-text-link($color-monochrome-600-inverse, $color-primary-200-inverse, $color-monochrome-300-inverse);
}

.#{$ws10-css-prefix}-footnote-link,
.#{$ws10-css-prefix}-footnote-link-inverse {
    font-weight: bold;
    font-size: $font-size-50;
    line-height: $line-height-100;

    &:has(sup) {
        text-decoration: $text-decoration-none;
        vertical-align: super;
    }

    sup {
        // overwrite a font-size coming from simplicity
        font-size: inherit;
        text-decoration: $text-decoration-underline;
        vertical-align: baseline;
    }
}

.#{$ws10-css-prefix}-footnote-link {
    @include ws10-text-link(inherit, inherit, none);
}

.#{$ws10-css-prefix}-footnote-link-inverse {
    @include ws10-text-link($color-monochrome-600-inverse, $color-monochrome-600-inverse, none);
}
