@import '../../../design-tokens/color';
@import '../precss/environment';

$bg-color-class-prefix: '#{$ws10-css-prefix}-background';

/**
 * Clean up this for a proper ws10 migration
 * For now: I just commented it out
 *
 * Option A: Update all classes to new colors
 * Option B: Find out where these helper classes are used, remove them and remove this file at all
 *
 * Bastian prefers Option B ;)
 */

/*
.#{$bg-color-class-prefix} {
    &-brand-50 {
        background: $color-accent-brand-50;
    }

    &-brand-100 {
        background: $color-brand-100;
    }

    &-brand-200 {
        background: $color-accent-brand-200;
    }

    &-brand-300 {
        background: $color-accent-brand-300;
    }

    &-brand-400 {
        background: $color-brand-400;
    }

    &-accent-blue-100 {
        background: $color-base-blue-100;
    }

    &-accent-blue-200 {
        background: $color-base-blue-200;
    }

    &-accent-purple-100 {
        background: $color-base-purple-100;
    }

    &-functional-yellow-100 {
        background: $color-base-yellow-100;
    }

    &-functional-green-100 {
        background: $color-base-green-100;
    }
}
*/
$bg-neutral-class-prefix: 'ws10-bg-neutral';

/* Needed for page background - Bastians problem is still unsolved */
@each $name, $color in ($colors-as-page-background) {
    .#{$bg-color-class-prefix}-#{$name} {
        background-color: $color;
    }
}

/*
.#{$bg-neutral-class-prefix} {
    &-black {
        background: $color-accent-black;
    }

    &-grey-600 {
        background: $color-monochrome-600;
    }

    &-grey-500 {
        background: $color-monochrome-2000;
    }

    &-grey-400 {
        background: $color-monochrome-400;
    }

    &-grey-300 {
        background: $color-accent-grey-300;
    }

    &-grey-200 {
        background: $color-accent-grey-200;
    }

    &-grey-100 {
        background: $color-monochrome-300;
    }

    &-grey-50 {
        background: $color-monochrome-200;
    }

    &-grey-25 {
        background: $color-monochrome-200;
    }

    &-white {
        background: $color-monochrome-100;
    }
}

 */
